export default ({ app }, inject) => {
  if (process.client) {
    const logRocketRoutes = ['/checkout', '/payments/_orderId', '/proof/_id'];

    const shouldInitLogRocket = (route) => {
      return logRocketRoutes.some((path) => {
        if (path.includes('_orderId')) {
          const regex = new RegExp('^/payments/[^/]+$');
          return regex.test(route.fullPath);
        }
        return route.fullPath === path;
      });
    };

    app.router.afterEach((to, from) => {
      if (process.env.NODE_ENV === 'production' && !process.env.IS_BOT_SERVER && shouldInitLogRocket(to)) {
        if (!window.LogRocketInitialized) {
          if (window.location?.href?.match(/print/)) return;
          window._lrAsyncScript = 'https://cdn.lr-ingest.com/logger.min.js';

          import('logrocket').then((LogRocket) => {
            LogRocket.default.init('wzhqhl/mpower-promo');
            inject('logRocket', LogRocket.default);
            window.LogRocketInitialized = true;
            console.log(`LogRocket initialized on ${to.fullPath}`);
          });
        }
      }
    });
  }
};
