import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7070d02e = () => interopDefault(import('../client/pages/cart/index.vue' /* webpackChunkName: "" */))
const _0aa540a2 = () => interopDefault(import('../client/pages/products/no-minimum/_.vue' /* webpackChunkName: "" */))
const _68e66df6 = () => interopDefault(import('../client/pages/products/closeout/_.vue' /* webpackChunkName: "" */))
const _39a64396 = () => interopDefault(import('../client/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _30b915ae = () => interopDefault(import('../client/pages/cartitemsadded/index.vue' /* webpackChunkName: "pages/cartitemsadded/index" */))
const _f793b44e = () => interopDefault(import('../client/pages/changepassword/index.vue' /* webpackChunkName: "pages/changepassword/index" */))
const _9228c46c = () => interopDefault(import('../client/pages/customer-artwork-proof/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/index" */))
const _4ac3107d = () => interopDefault(import('../client/pages/designs/index.vue' /* webpackChunkName: "pages/designs/index" */))
const _145f7aea = () => interopDefault(import('../client/pages/facets/index.vue' /* webpackChunkName: "pages/facets/index" */))
const _327b0ac2 = () => interopDefault(import('../client/pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _60531e8c = () => interopDefault(import('../client/pages/forgotpassword/index.vue' /* webpackChunkName: "pages/forgotpassword/index" */))
const _30f2ee67 = () => interopDefault(import('../client/pages/health.vue' /* webpackChunkName: "pages/health" */))
const _4b33d9e0 = () => interopDefault(import('../client/pages/not-found/index.vue' /* webpackChunkName: "pages/not-found/index" */))
const _8fca5868 = () => interopDefault(import('../client/pages/proof/index.vue' /* webpackChunkName: "pages/proof/index" */))
const _01bca868 = () => interopDefault(import('../client/pages/proofitemsadded/index.vue' /* webpackChunkName: "pages/proofitemsadded/index" */))
const _0b5a5504 = () => interopDefault(import('../client/pages/quoteitemsadded/index.vue' /* webpackChunkName: "pages/quoteitemsadded/index" */))
const _575c8ef2 = () => interopDefault(import('../client/pages/resetpassword/index.vue' /* webpackChunkName: "pages/resetpassword/index" */))
const _9504b310 = () => interopDefault(import('../client/pages/sampleitemsadded/index.vue' /* webpackChunkName: "pages/sampleitemsadded/index" */))
const _049bd970 = () => interopDefault(import('../client/pages/signin/index.vue' /* webpackChunkName: "pages/signin/index" */))
const _063a290e = () => interopDefault(import('../client/pages/signout/index.vue' /* webpackChunkName: "pages/signout/index" */))
const _00994c6f = () => interopDefault(import('../client/pages/suppliers/index.vue' /* webpackChunkName: "pages/suppliers/index" */))
const _d67a4e22 = () => interopDefault(import('../client/pages/testy/index.vue' /* webpackChunkName: "pages/testy/index" */))
const _e9da6b46 = () => interopDefault(import('../client/pages/version.vue' /* webpackChunkName: "pages/version" */))
const _5eba4274 = () => interopDefault(import('../client/pages/version-app/index.vue' /* webpackChunkName: "pages/version-app/index" */))
const _7c8906b0 = () => interopDefault(import('../client/pages/cat/GeneralProductView.vue' /* webpackChunkName: "pages/cat/GeneralProductView" */))
const _090f6316 = () => interopDefault(import('../client/pages/orders/products/index.vue' /* webpackChunkName: "pages/orders/products/index" */))
const _79a1811e = () => interopDefault(import('../client/pages/products/utils.js' /* webpackChunkName: "pages/products/utils" */))
const _0214b1e5 = () => interopDefault(import('../client/pages/products/search/mixin.js' /* webpackChunkName: "pages/products/search/mixin" */))
const _68c23520 = () => interopDefault(import('../client/pages/products/search/search.js' /* webpackChunkName: "pages/products/search/search" */))
const _25f1f119 = () => interopDefault(import('../client/pages/sso/facebook/delete.vue' /* webpackChunkName: "pages/sso/facebook/delete" */))
const _01dff71f = () => interopDefault(import('../client/pages/sso/punch-out/_source.vue' /* webpackChunkName: "pages/sso/punch-out/_source" */))
const _fe8404fa = () => interopDefault(import('../client/pages/review/order/_orderId/product/_productId.vue' /* webpackChunkName: "pages/review/order/_orderId/product/_productId" */))
const _038a9dc8 = () => interopDefault(import('../client/pages/products/search/_.vue' /* webpackChunkName: "pages/products/search/_" */))
const _58561fc4 = () => interopDefault(import('../client/pages/blog/cat/_.vue' /* webpackChunkName: "pages/blog/cat/_" */))
const _53b73ac4 = () => interopDefault(import('../client/pages/approval/_orderId/index.vue' /* webpackChunkName: "pages/approval/_orderId/index" */))
const _50fa3751 = () => interopDefault(import('../client/pages/artproof/_productSlug/index.vue' /* webpackChunkName: "pages/artproof/_productSlug/index" */))
const _09c07c56 = () => interopDefault(import('../client/pages/authors/_authorName/index.vue' /* webpackChunkName: "pages/authors/_authorName/index" */))
const _54aa894c = () => interopDefault(import('../client/pages/components/_componentId/index.vue' /* webpackChunkName: "pages/components/_componentId/index" */))
const _1b667ac9 = () => interopDefault(import('../client/pages/configureproduct/_productSlug/index.vue' /* webpackChunkName: "pages/configureproduct/_productSlug/index" */))
const _6fccc3b5 = () => interopDefault(import('../client/pages/customer-artwork-proof/_id/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/_id/index" */))
const _14793432 = () => interopDefault(import('../client/pages/designer/_productSlug/index.vue' /* webpackChunkName: "pages/designer/_productSlug/index" */))
const _2b33f9f8 = () => interopDefault(import('../client/pages/designs/_slug/index.vue' /* webpackChunkName: "pages/designs/_slug/index" */))
const _2a601ea5 = () => interopDefault(import('../client/pages/facets/_slug/index.vue' /* webpackChunkName: "pages/facets/_slug/index" */))
const _36b388b1 = () => interopDefault(import('../client/pages/largequote/_productSlug/index.vue' /* webpackChunkName: "pages/largequote/_productSlug/index" */))
const _53878344 = () => interopDefault(import('../client/pages/orders/_id/index.vue' /* webpackChunkName: "pages/orders/_id/index" */))
const _0b06a518 = () => interopDefault(import('../client/pages/payments/_orderId/index.vue' /* webpackChunkName: "pages/payments/_orderId/index" */))
const _20a895e7 = () => interopDefault(import('../client/pages/presentation/_orderId/index.vue' /* webpackChunkName: "pages/presentation/_orderId/index" */))
const _4699e368 = () => interopDefault(import('../client/pages/presentations/_id/index.vue' /* webpackChunkName: "pages/presentations/_id/index" */))
const _6f01813e = () => interopDefault(import('../client/pages/printinvoice/_orderId/index.vue' /* webpackChunkName: "pages/printinvoice/_orderId/index" */))
const _742eadad = () => interopDefault(import('../client/pages/products/_slug/index.vue' /* webpackChunkName: "pages/products/_slug/index" */))
const _3a8d28b7 = () => interopDefault(import('../client/pages/proof/_id/index.vue' /* webpackChunkName: "pages/proof/_id/index" */))
const _eea6573a = () => interopDefault(import('../client/pages/quickquote/_productSlug/index.vue' /* webpackChunkName: "pages/quickquote/_productSlug/index" */))
const _8d5ea624 = () => interopDefault(import('../client/pages/reorder/_id/index.vue' /* webpackChunkName: "pages/reorder/_id/index" */))
const _3076d788 = () => interopDefault(import('../client/pages/sample/_productSlug/index.vue' /* webpackChunkName: "pages/sample/_productSlug/index" */))
const _ff44c37e = () => interopDefault(import('../client/pages/sso/_service.vue' /* webpackChunkName: "pages/sso/_service" */))
const _a650bfe4 = () => interopDefault(import('../client/pages/cat/_slug/brand.vue' /* webpackChunkName: "pages/cat/_slug/brand" */))
const _75110983 = () => interopDefault(import('../client/pages/orders/_id/track-shipment/index.vue' /* webpackChunkName: "pages/orders/_id/track-shipment/index" */))
const _7fbfb60b = () => interopDefault(import('../client/pages/printinvoice/_orderId/short.vue' /* webpackChunkName: "pages/printinvoice/_orderId/short" */))
const _6434e7b6 = () => interopDefault(import('../client/pages/products/_slug/reviews.vue' /* webpackChunkName: "pages/products/_slug/reviews" */))
const _14819538 = () => interopDefault(import('../client/pages/facets/_slug/_facet/index.vue' /* webpackChunkName: "pages/facets/_slug/_facet/index" */))
const _cbc1fdf2 = () => interopDefault(import('../client/pages/supplier/_psCode/_.vue' /* webpackChunkName: "pages/supplier/_psCode/_" */))
const _ea758f34 = () => interopDefault(import('../client/pages/cat/_slug/_.vue' /* webpackChunkName: "pages/cat/_slug/_" */))
const _79518824 = () => interopDefault(import('../client/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/box/cart",
    component: _7070d02e,
    name: "box/cart"
  }, {
    path: "/quote/cart",
    component: _7070d02e,
    name: "quote/cart"
  }, {
    path: "/sample/cart",
    component: _7070d02e,
    name: "sample/cart"
  }, {
    path: "/proof/cart",
    component: _7070d02e,
    name: "proof/cart"
  }, {
    path: "/boxcart",
    component: _7070d02e,
    name: "boxcart"
  }, {
    path: "/quotecart",
    component: _7070d02e,
    name: "quotecart"
  }, {
    path: "/samplecart",
    component: _7070d02e,
    name: "samplecart"
  }, {
    path: "/proofcart",
    component: _7070d02e,
    name: "proofcart"
  }, {
    path: "/products/no-minimum",
    component: _0aa540a2,
    name: "products-no-minimum"
  }, {
    path: "/products/closeout",
    component: _68e66df6,
    name: "products-closeout"
  }, {
    path: "/account",
    component: _39a64396,
    name: "account"
  }, {
    path: "/cart",
    component: _7070d02e,
    name: "cart"
  }, {
    path: "/cartitemsadded",
    component: _30b915ae,
    name: "cartitemsadded"
  }, {
    path: "/changepassword",
    component: _f793b44e,
    name: "changepassword"
  }, {
    path: "/customer-artwork-proof",
    component: _9228c46c,
    name: "customer-artwork-proof"
  }, {
    path: "/designs",
    component: _4ac3107d,
    name: "designs"
  }, {
    path: "/facets",
    component: _145f7aea,
    name: "facets"
  }, {
    path: "/favorites",
    component: _327b0ac2,
    name: "favorites"
  }, {
    path: "/forgotpassword",
    component: _60531e8c,
    name: "forgotpassword"
  }, {
    path: "/health",
    component: _30f2ee67,
    name: "health"
  }, {
    path: "/not-found",
    component: _4b33d9e0,
    name: "not-found"
  }, {
    path: "/proof",
    component: _8fca5868,
    name: "proof"
  }, {
    path: "/proofitemsadded",
    component: _01bca868,
    name: "proofitemsadded"
  }, {
    path: "/quoteitemsadded",
    component: _0b5a5504,
    name: "quoteitemsadded"
  }, {
    path: "/resetpassword",
    component: _575c8ef2,
    name: "resetpassword"
  }, {
    path: "/sampleitemsadded",
    component: _9504b310,
    name: "sampleitemsadded"
  }, {
    path: "/signin",
    component: _049bd970,
    name: "signin"
  }, {
    path: "/signout",
    component: _063a290e,
    name: "signout"
  }, {
    path: "/suppliers",
    component: _00994c6f,
    name: "suppliers"
  }, {
    path: "/testy",
    component: _d67a4e22,
    name: "testy"
  }, {
    path: "/version",
    component: _e9da6b46,
    name: "version"
  }, {
    path: "/version-app",
    component: _5eba4274,
    name: "version-app"
  }, {
    path: "/cat/GeneralProductView",
    component: _7c8906b0,
    name: "cat-GeneralProductView"
  }, {
    path: "/orders/products",
    component: _090f6316,
    name: "orders-products"
  }, {
    path: "/products/utils",
    component: _79a1811e,
    name: "products-utils"
  }, {
    path: "/products/search/mixin",
    component: _0214b1e5,
    name: "products-search-mixin"
  }, {
    path: "/products/search/search",
    component: _68c23520,
    name: "products-search-search"
  }, {
    path: "/sso/facebook/delete",
    component: _25f1f119,
    name: "sso-facebook-delete"
  }, {
    path: "/sso/punch-out/:source?",
    component: _01dff71f,
    name: "sso-punch-out-source"
  }, {
    path: "/review/order/:orderId?/product/:productId?",
    component: _fe8404fa,
    name: "review-order-orderId-product-productId"
  }, {
    path: "/products/search/*",
    component: _038a9dc8,
    name: "products-search-all"
  }, {
    path: "/products/no-minimum/*",
    component: _0aa540a2,
    name: "products-no-minimum-all"
  }, {
    path: "/products/closeout/*",
    component: _68e66df6,
    name: "products-closeout-all"
  }, {
    path: "/blog/cat/*",
    component: _58561fc4,
    name: "blog-cat-all"
  }, {
    path: "/approval/:orderId",
    component: _53b73ac4,
    name: "approval-orderId"
  }, {
    path: "/artproof/:productSlug",
    component: _50fa3751,
    name: "artproof-productSlug"
  }, {
    path: "/authors/:authorName",
    component: _09c07c56,
    name: "authors-authorName"
  }, {
    path: "/components/:componentId",
    component: _54aa894c,
    name: "components-componentId"
  }, {
    path: "/configureproduct/:productSlug",
    component: _1b667ac9,
    name: "configureproduct-productSlug"
  }, {
    path: "/customer-artwork-proof/:id",
    component: _6fccc3b5,
    name: "customer-artwork-proof-id"
  }, {
    path: "/designer/:productSlug",
    component: _14793432,
    name: "designer-productSlug"
  }, {
    path: "/designs/:slug",
    component: _2b33f9f8,
    name: "designs-slug"
  }, {
    path: "/facets/:slug",
    component: _2a601ea5,
    name: "facets-slug"
  }, {
    path: "/largequote/:productSlug",
    component: _36b388b1,
    name: "largequote-productSlug"
  }, {
    path: "/orders/:id",
    component: _53878344,
    name: "orders-id"
  }, {
    path: "/payments/:orderId",
    component: _0b06a518,
    name: "payments-orderId"
  }, {
    path: "/presentation/:orderId",
    component: _20a895e7,
    name: "presentation-orderId"
  }, {
    path: "/presentations/:id",
    component: _4699e368,
    name: "presentations-id"
  }, {
    path: "/printinvoice/:orderId",
    component: _6f01813e,
    name: "printinvoice-orderId"
  }, {
    path: "/products/:slug",
    component: _742eadad,
    name: "products-slug"
  }, {
    path: "/proof/:id",
    component: _3a8d28b7,
    name: "proof-id"
  }, {
    path: "/quickquote/:productSlug",
    component: _eea6573a,
    name: "quickquote-productSlug"
  }, {
    path: "/reorder/:id",
    component: _8d5ea624,
    name: "reorder-id"
  }, {
    path: "/sample/:productSlug",
    component: _3076d788,
    name: "sample-productSlug"
  }, {
    path: "/sso/:service?",
    component: _ff44c37e,
    name: "sso-service"
  }, {
    path: "/cat/:slug?/brand",
    component: _a650bfe4,
    name: "cat-slug-brand"
  }, {
    path: "/orders/:id?/track-shipment",
    component: _75110983,
    name: "orders-id-track-shipment"
  }, {
    path: "/printinvoice/:orderId?/short",
    component: _7fbfb60b,
    name: "printinvoice-orderId-short"
  }, {
    path: "/products/:slug?/reviews",
    component: _6434e7b6,
    name: "products-slug-reviews"
  }, {
    path: "/facets/:slug/:facet",
    component: _14819538,
    name: "facets-slug-facet"
  }, {
    path: "/supplier/:psCode?/*",
    component: _cbc1fdf2,
    name: "supplier-psCode-all"
  }, {
    path: "/cat/:slug?/*",
    component: _ea758f34,
    name: "cat-slug-all"
  }, {
    path: "/*",
    component: _79518824,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
