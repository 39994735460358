const axios = require('axios');

const VERSION = '2.0.8';
const headers = {
  version: VERSION,
  apitoken: process.env.API_TOKEN || 'qweQj4giRJSdMNzB8g1XIa6t3YtRIHPH',
  [`User-Agent-1`]: `Mpower-store`,
};
const axiosConfig = {
  baseURL: process.env.API_URL || 'http://localhost:3001',
  headers,
};
export const HTTP = axios.create(axiosConfig);

export default async function(req, res, next) {
  try {
    const link = decodeURI(req.url);
    // const path = link.split(`\?`)[0];

    // if (path && path.match(/[^a-zA-Z0-9\/\-\.\_]/g)) {
    //   throw `Bad path ${req.url}`;
    // }

    let host = process.env.STORE_URL || req.headers.host;
    const hasId = host.split('.').find((e) => e.toString().match(/^[0-9a-fA-F]{24}$/));
    if (hasId) host = req.headers['X-Real-Host'] || hasId;
    // host = 'mpowerpromo.com';
    // console.log('seo', req.headers, req.url);
    if (req.url.includes('xml')) {
      // console.log('seo:xml', host);
      try {
        const siteMap = (
          await HTTP.post(`/stores/${host}/sitemaps/download`, {
            path: req.url.replace('/', ''),
            host,
            ...headers,
          })
        ).data;
        res.setHeader('Content-Type', 'application/xml');
        res.end(siteMap);
      } catch (err) {
        console.log(err);
        // next(err);
        res.writeHead(301, { Location: '/not-found' });
        res.end();
      }
    } else if (req.url.includes('txt')) {
      try {
        const robot = (
          await HTTP.post(`/stores/${host}/robots/download`, { host, ...headers, path: req.url.replace('/', '') })
        ).data;
        res.setHeader('Content-Type', 'text/plain');
        res.end(robot || 'empty');
      } catch (err) {
        console.log(err, err.response.data);
        // next(err);
        // res.writeHead(301, { Location: '/not-found' });
        res.setHeader('Content-Type', 'text/plain');
        res.end(
          [
            'not found file ',
            err.toString(),
            req.url,
            host,
            JSON.stringify({ axiosConfig, headers: req.headers, host }),
          ].join('; ')
        );
      }
    } else {
      next();
    }
  } catch (e) {
    res.writeHead(301, { Location: '/not-found' });
    res.end();
  }
}
