import { loadScripts } from './scripts';

const momentJs = [
  { type: 'script', src: 'https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.29.4/moment.min.js' },
  {
    type: 'script',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/moment-timezone/0.5.34/moment-timezone-with-data.min.js',
  },
];

const mPowerMoment = function(v) {
  loadScripts([momentJs[0]]).then(
    loadScripts([momentJs[1]]).then((result) => {
      if (result > 0 || (process.client && window.loaded && window.loaded[momentJs[0].src] !== 2)) {
        setTimeout(() => {
          if (this && typeof this.$forceUpdate === 'function') {
            this.$forceUpdate();
          }
        }, 1000);
      }
    })
  );
  return {
    format: (...e) => (process.client && window.moment && window.moment(v).format(...e)) || '',
    add: (...e) => (process.client && window?.moment && window.moment(v).format(...e)) || v,
    startOf: (...e) => (process.client && window?.moment && window.moment(v).format(...e)) || v,
    endOf: (...e) => (process.client && window?.moment && window.moment(v).endOf(...e)) || v,
    tz: (...e) => (process.client && window?.moment && window.moment(v).tz(...e)) || v,
    // utc: (...e) => (process.client && window?.moment && window.moment(v).utc(...e)) || v,
  };
};
mPowerMoment.utc = () => (process.client && window?.moment && window.moment.utc()) || new Date().toUTCString();

export default mPowerMoment;
