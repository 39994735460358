const SOCKET_CUSTOM_EVENT_NAME = {
  CLIENT_INIT: 'CLIENT_INIT',
  CART_UPDATED: 'CART_UPDATED',
  CART_UPDATED_FRESH: 'CART_UPDATED_FRESH',
};

const initScript = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.onload = resolve;
    script.onerror = reject;
    script.setAttribute('src', 'https://cdn.socket.io/4.4.1/socket.io.min.js');
    script.setAttribute('crossorigin', 'anonymous');
    document.body.appendChild(script);
  });
};

const Cookie = process.client ? require('js-cookie') : undefined;
export const initSocket = process.client
  ? async (apiUrl, token, $axios, store) => {
      if (window.socket_client) return;
      document.body.onclick = async (e) => {
        if (!e.isTrusted || (store && store.rootState && !store.rootState.cart.orders.length)) {
          // console.log('mpower-logs: error  init socket', e.isTrusted, store?.rootState.cart.orders.length);
          return;
        }
        //only if user interact
        document.body.onclick = null;
        let socket;
        try {
          console.log('initSocket');
          await initScript();
          const options = {
            // rememberUpgrade: true,
            transports: ['websocket', 'polling'],
            secure: !!location.origin.match('https'),
            reconnectionAttempts: 'Infinity',
            timeout: 100001,
            withCredentials: false,
            rejectUnauthorized: false,
            reconnection: true,
            query: {
              token,
              orderId: store.rootState.cart.orders.map((e) => e._orderId || e.orderId),
            },
          };
          socket = window.io(apiUrl, options);
          window.socket_client = socket;
          socket.on(SOCKET_CUSTOM_EVENT_NAME.CLIENT_INIT, (id) => {
            $axios.defaults.headers.common['socketId'] = id;
          });
          socket.on('connect', () => {
            console.log('client connected');
            try {
              const orders = [...new Set(store.rootState.cart.orders.map((e) => e._id))];
              store.dispatch(
                'cart/loadOrders',
                {
                  orders,
                  customerId: store.rootState.auth.user,
                  onlyCount: false,
                  storeInformation: store.rootState.store,
                  forceLoad: true,
                },
                { root: true }
              );
            } catch (e) {
              console.log(e);
            }
          });
          socket.on('disconnect', () => {
            console.log('client disconnect');
            $axios.defaults.headers.common['socketId'] = '';
          });
          socket.on('error', (e) => {
            console.log('socket error', e);
          });
          socket.on('connect_error', (e) => {
            console.log('socket error', e);
          });
          socket.on(SOCKET_CUSTOM_EVENT_NAME.CART_UPDATED_FRESH, (data) => {
            try {
              const orders = [...new Set(store.rootState.cart.orders.map((e) => e._id))];
              if (orders.includes(data.order._id)) {
                store.dispatch(
                  'cart/loadOrders',
                  {
                    orders,
                    customerId: store.rootState.auth.user,
                    onlyCount: false,
                    storeInformation: store.rootState.store,
                    forceLoad: true,
                  },
                  { root: true }
                );
              }
            } catch (e) {
              console.log(e);
            }
          });
          socket.on(SOCKET_CUSTOM_EVENT_NAME.CART_UPDATED, (data) => {
            try {
              if (data.order && data.order._id && store.rootState.cart.orders.find((e) => e._id === data.order._id)) {
                Cookie.remove('cartOrders');
                if (process.client) {
                  window.location.reload();
                }
              }
            } catch (e) {
              console.log(e);
            }
          });
        } catch (e) {
          console.log(e);
        }
        return socket;
      };
    }
  : () => 0;
