
  export default {
    data: () => ({
      loading: false,
      checkingVersion: false,
      interval: false,
      isIE: false,
    }),
    async created() {
      if (process.client) {
        const ua = window.navigator.userAgent;
        this.isIE = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;

        if (this.isIE) {
          window.onNuxtReady((app) => {
            document.querySelector('html').className = '';
            document.querySelector('body').className = '';
            this.interval = setInterval(() => {
              document.querySelector('html').className = '';
              document.querySelector('body').className = '';
            }, 10000);
          });
        }
      }
    },
    destroyed() {
      if (this.interval) clearInterval(this.interval);
    },
  };
