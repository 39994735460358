import decode from 'jwt-decode';
import { initSocket } from '../utils/socket';
const Cookie = process.client ? require('js-cookie') : undefined;
import { initStoreCustomer } from '../plugins/dataLayer.mixin.js';

export const state = () => ({
  sourceId: null,
  source: null,
  socket_client: null,
  token: null,
  status: '',
  searchToken: '',
  err: {},
  hasLoadedOnce: false,
  user: null,
  customerAccountManager: null,
});

export const getters = {
  source: (state) => state.source,
  searchToken: (state) => state.searchToken,
  sourceId: (state) => state.sourceId,
  token: (state) => state.token,
  isAuthenticated: (state) => !!state.token,
  loggedInUser: (state) => state.user,
  customerAccountManager: (state) => state.customerAccountManager,
  isAdmin: (state) => state.user && !!(state.user.roles || []).find((el) => el.name?.match(/admin/i) || el.isAdmin),
  authStatus: (state) => state.status,
  stateError: (state) => state.err,
};
export const actions = {
  searchToken({ commit }, value) {
    commit('searchToken', value);
  },
  get_account_manager({ commit, dispatch }, userId) {
    return this.$axios.get(`/users/${userId}`).then((resp) => {
      commit('ACCOUNT_MANAGER_SUCCESS', resp.data);
      return resp.data;
    });
  },
  auth_request({ commit, dispatch }, user) {
    commit('AUTH_REQUEST');
    return this.$axios
      .post('/customers/authorization/login', user)
      .then((resp) => {
        dispatch('auth_login', resp.data);
        return resp.data.customer;
      })
      .catch((err) => {
        commit('AUTH_ERROR', err);
        Cookie.remove('auth');
        Cookie.remove('source');
        Cookie.remove('sourceId');
      });
  },
  async auth_request_token({ dispatch }, token) {
    return this.$axios.get(`/customers/${token}/info`).then((resp) => {
      dispatch('auth_login', resp.data);
      return resp.data.customer;
    });
  },
  async auth_login({ commit, ...store }, { token, customer }) {
    Cookie.set('auth', token);
    const completedWalkthroughs = Cookie.getJSON('completedWalkthroughs') || [];
    if (completedWalkthroughs.length) {
      if (!customer.completedWalkthroughs) customer.completedWalkthroughs = [];
      customer.completedWalkthroughs.push(
        ...completedWalkthroughs.filter((i) => !customer.completedWalkthroughs.includes(i))
      );
      this.$api.customers.updateCustomer(customer);
      Cookie.remove('completedWalkthroughs');
    }
    this.$axios.defaults.headers.common['Authorization'] = token;

    commit('AUTH_SUCCESS', { customer, token });
    initStoreCustomer(customer, store.rootState?.store);
    await initSocket(store.rootState.API_URL, store.state.token, this.$axios, store);
  },
  async checkSocket({ commit, ...store }) {
    await initSocket(store.rootState.API_URL, store.state.token, this.$axios, store);
  },
  auth_logout({ commit, dispatch }) {
    Cookie.remove('cartOrders');
    Cookie.remove('auth');
    Cookie.remove('source');
    Cookie.remove('sourceId');
    dispatch('cart/clear', {}, { root: true });
    dispatch('favorites/clear', {}, { root: true });
    dispatch('creditsAllowances/clear', {}, { root: true });
    commit('AUTH_LOGOUT');
    delete this.$axios.defaults.headers.common['Authorization'];
    delete this.$axios.defaults.headers.common['mpower-source'];
    delete this.$axios.defaults.headers.common['mpower-source-id'];
  },
};
export const mutations = {
  searchToken(state, value) {
    state.searchToken = value;
  },
  AUTH_REQUEST(state) {
    state.status = 'loading';
  },
  SET_SOURCE(state, { source, sourceId }) {
    state.sourceId = sourceId;
    state.source = source;
  },
  AUTH_SUCCESS(state, { token, customer, API_URL }) {
    state.status = 'success';
    state.token = token;
    state.hasLoadedOnce = true;

    state.user = customer || decode(token);
  },
  AUTH_ERROR(state, error) {
    state.status = 'error';
    state.err.message = error.response.data.message;
    state.err.status = error.response.status;
    state.hasLoadedOnce = true;
  },
  AUTH_LOGOUT(state) {
    state.token = '';
    state.user = null;
    state.sourceId = null;
    state.source = null;
    // window.socket_client && window.socket_client.disconnect();
    // window.socket_client = null;
  },
  ACCOUNT_MANAGER_SUCCESS(state, user) {
    state.customerAccountManager = user;
  },
};
