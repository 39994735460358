import Vue from 'vue';
import VeeValidate from 'vee-validate';
import { extendValidators } from '@mtntop/utils/src/plugins/validationRules';

Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
});

extendValidators(VeeValidate);
